import { ArticleSection } from '@/types';
import styles from './ComponentInThisArticle.module.scss';
export const ComponentInThisArticle = ({
  title,
  articleSections,
}: {
  title: string;
  articleSections: ArticleSection[];
}) => {
  const sectionsToShow = articleSections.filter(
    (section) => section.showInArticleNavigation
  );

  return (
    <div className={styles.inThisArticle}>
      <div className={styles.inThisArticle__title}>{title}</div>

      <div className={styles.inThisArticle__links}>
        {sectionsToShow.length > 0 ? (
          <ul className={styles.inThisArticle__list}>
            {sectionsToShow.map((section: ArticleSection, index: number) => (
              <li
                key={`showInArticleNavigation_${section.sys.id}_${index}_key`}
                className={styles.inThisArticle__listItem}
              >
                <a tabIndex={0} href={`#section_index_${index}`}>
                  {section.articleTitle}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <div className={styles.noSelectionMessage}>
            <ul className={styles.inThisArticle__list}>
              
                <li
                  key={`showInArticleNavigation_noTitles`}
                  className={styles.inThisArticle__listItem}
                >
                  Please set a section to 'Show in Article Navigation'
                </li>
              
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentInThisArticle;
