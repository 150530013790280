import styles from './ComponentArticleFootnote.module.scss';

import { NavigationTag } from '@/types';
import { TagPill, AuthorCardSmall } from '@/ui';

import { ComponentRichText } from '../../ComponentRichText';

const ComponentArticleFootnote = (writtenBy: any) => {
  return (
    <div className={styles.footnote}>
      <div className={styles.footnote__container}>
        {writtenBy.writtenBy ? (
          <div className={styles.footnote__inner}>
            <div className={styles.footnote__left}>
              <div className={styles.footnote__leftTop}>
                <AuthorCardSmall
                  employeeImage={writtenBy.writtenBy.employeeImage}
                  employeeName={writtenBy.writtenBy.employeeName}
                  employeeJobTitle={writtenBy.writtenBy.employeeJobTitle}
                />
              </div>
              <div className={styles.footnote__leftBottom}>
                <div className={styles.footnote__authorBio}>
                  {writtenBy.writtenBy.employeeBio && (
                    <ComponentRichText
                      richTextBody={writtenBy.writtenBy.employeeBio}
                      isStandalone={false}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.footnote__right}>
              <div className={styles.footnote__relatedTopics}>
                {writtenBy.writtenBy.employeeTag?.length > 0 && (
                  <>
                    <div className={styles.footnote__relatedTitle}>
                      Related Topics
                    </div>
                    <div className={styles.footnote__relatedList}>
                      {writtenBy.writtenBy.employeeTag?.map(
                        (tag: NavigationTag) => {
                          return (
                            <TagPill
                              key={'employee_related_tags_' + tag.tagText}
                              text={tag.tagText}
                            />
                          );
                        }
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.footnote__inner}>
            <div className={styles.footnote__left}>
              <div className={styles.footnote__leftTop}>
                <div className={styles.footnote__authorInfo}>
                  <div className={styles.footnote__authorName}>
                    This article has no author set
                  </div>
                </div>
              </div>
              <div className={styles.footnote__leftBottom}>
                <div className={styles.footnote__authorBio}>
                  Please either set an author for the article or turn off 'Show
                  Article Author Footnote'
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentArticleFootnote;
