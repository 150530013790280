import styles from './ComponentArticleContent.module.scss';

import React from 'react';

import { useIsMobile } from '@/ui/utils';
import { ArticleContent, ArticleSection } from '@/types';
import {
  ComponentCtaButton,
  DynamicHeading,
  MediaCard,
  RelatedInsightWrapper,
  ShareThisWrapper,
  getRelatedInsightsWrapperProps,
} from '@/ui';

import { ComponentRichText } from '../ComponentRichText';
import { ComponentArticleFootnote } from './ComponentArticleFootnote';
import { ComponentInThisArticle } from './ComponentInThisArticle';

export const ComponentArticleContent = ({
  pageData,
  articleSections,
  relatedInsights,
  inThisArticleText,
  shareText,
  showInThisArticle,
  dataDrivenInsightText,
  showShare,
  showArticleAuthorFootnote,
}: ArticleContent) => {
  const hasSections = !!articleSections.length;

  const isMobile = useIsMobile();

  const relatedInsightsWrapperProps = getRelatedInsightsWrapperProps({
    component: relatedInsights,
    heading: dataDrivenInsightText,
    headingVariant: 'h3',
  });

  return (
    <div className={styles.articleContent}>
      <div className={styles.articleContent__container}>
        <div className={styles.articleContent__grid}>
          <div className={`${styles.articleContent__main}`}>
            {showInThisArticle && isMobile && (
              <ComponentInThisArticle
                articleSections={articleSections}
                title={inThisArticleText}
              />
            )}

            <div className={styles.articleContent__text}>
              {hasSections &&
                articleSections.map(
                  (section: ArticleSection, index: number) => {
                    return (
                      <div
                        key={`article_section_${section.sys.id}_${index}_key`}
                        className={styles.articleContent__articleSection}
                      >
                        <div
                          className={
                            styles.articleContent__articleSectionAnchor
                          }
                          id={`section_index_${index}`}
                        ></div>

                        <div
                          className={styles.articleContent__articleSectionTitle}
                        >
                          <DynamicHeading
                            variant={'h2'}
                            className={
                              styles.articleContent__articleSectionHeading
                            }
                          >
                            {section.articleTitle}
                          </DynamicHeading>
                        </div>
                        <div
                          className={styles.articleContent__articleSectionBody}
                        >
                          {section.sectionBodyComponents.map(
                            (component: any, index: number) => {
                              return (
                                <React.Fragment
                                  key={`component_${component.sys.id}_${index}_key`}
                                >
                                  {component.__typename === 'DataRichText' && (
                                    <div
                                      className={
                                        styles.articleContent__textBody
                                      }
                                    >
                                      <ComponentRichText
                                        richTextBody={component.bodyContent}
                                        isStandalone={false}
                                      />
                                    </div>
                                  )}

                                  {component.__typename === 'DataVideo' && (
                                    <div
                                      className={
                                        styles.articleContent__mediaCard
                                      }
                                    >
                                      <MediaCard {...component} />
                                    </div>
                                  )}

                                  {component.__typename === 'ComponentCta' && (
                                    <div
                                      className={
                                        styles.articleContent__componentCta
                                      }
                                    >
                                      <ComponentCtaButton {...component} />
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
          <div className={styles.articleContent__aside}>
            <div className={styles.articleContent__asideInner}>
              {showInThisArticle && !isMobile && (
                <ComponentInThisArticle
                  articleSections={articleSections}
                  title={inThisArticleText}
                />
              )}

              {showShare && (
                <div className={styles.articleContent__share}>
                  <ShareThisWrapper text={shareText} />
                </div>
              )}

              {relatedInsightsWrapperProps && (
                <RelatedInsightWrapper {...relatedInsightsWrapperProps} />
              )}
            </div>
          </div>
        </div>
      </div>

      {showArticleAuthorFootnote && (
        <ComponentArticleFootnote writtenBy={pageData.writtenBy} />
      )}
    </div>
  );
};

export default ComponentArticleContent;
